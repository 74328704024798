html[data-style-scope='welcome'] {
  main {
    max-width: 400px;
    margin: 0 auto;
    padding: 2em 0.6em;
  }

  h1 {
    font-weight: bold;
    font-size: 1.4em;
  }

  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dfn
  dfn {
    border-bottom: 2px solid orange;
    background-color: #ffff0099;
    color: var(--bs-dark);
    font-style: normal;
  }
}
