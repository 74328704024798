@keyframes hero-iphone-semi-right-fade-in {
  0% {
    opacity: 0;
    transform: translateY(8%) translateX(4%) rotate(0.8deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0) translateX(0) rotate(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
