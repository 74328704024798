/* Capacitor-specific MarCom page(s) */

html[data-style-scope='marcom-capacitor'] {
  body {
    font-size: 20px;
    background-color: var(--bs-black);
  }

  header {
    background-color: var(--bs-black);
  }

  .wrapper {
    max-width: 1000px;
    margin: 0 auto;
    padding: 1em 2em;
  }

  h1,
  h2 {
    margin-bottom: 0;
  }

  main {
    overflow: hidden;
    background-color: #f5f5f5;

    .wrapper {
      padding-top: 2em;
      padding-bottom: 2em;
    }
  }

  header {
    --header-color: white;
    color: var(--header-color);

    img.logo {
      filter: invert(1);
    }
  }

  .hero {
    text-align: center;
    color: white;
    background: radial-gradient(at bottom, #090979 25%, black 100%);
    padding-bottom: 2em;
    height: calc(100vh - 70px - env(safe-area-inset-top)) !important;

    .wrapper {
      padding-bottom: 0;

      &.top-content {
        overflow: hidden;
        height: 100%;
      }
    }

    h1 {
      font-size: 1.8em;
      font-weight: 700;
    }

    hr {
      margin: 0 auto;
      width: 10%;
    }
  }
}

@media (max-width: 420px) {
  html[data-style-scope='mobile'] .hero h1 {
    font-size: 1.3em;
  }
}
