html[data-style-scope='app'] {
  .transaction {
    transition: all 300ms;
  }

  .transaction-name mark {
    padding-left: 0;
    padding-right: 0;
  }

  .transaction.highlight-shimmer {
    background-image: linear-gradient(-65deg, transparent 45%, rgba(0, 0, 0, 0.2) 50%, transparent 55%);
    background-size: 300%;
    background-position-x: 100%;
    animation: highlight-shimmer 1s linear;
    animation-iteration-count: 2;
  }

  @keyframes highlight-shimmer {
    to {
      background-position-x: 0%;
    }
  }

  legend {
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--bs-secondary-color);
  }
}

html[data-bs-theme='dark'] {
  .transaction.highlight-shimmer {
    background-image: linear-gradient(-65deg, transparent 45%, rgba(255, 255, 255, 0.2) 50%, transparent 55%);
  }
}
