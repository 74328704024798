@use 'breakpoints';
@use '../keyframes';

@import 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300&display=swap';

html[data-style-scope='marcom'] {
  header {
    --header-color: white;
    color: var(--header-color);
    img.logo {
      filter: invert(1);
    }
    .btn-outline-primary {
      color: inherit;
      border-color: var(--header-color);
      &:hover {
        color: var(--bs-black);
        background-color: var(--header-color);
      }
    }
    .btn-link {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .qrcode {
    h2 {
      font-size: calc(0.7rem + 0.9vw);
      font-weight: normal;
    }
  }

  #hero {
    background: radial-gradient(at bottom, #090979 25%, black 100%);

    h1 {
      color: white;
    }

    .display-6,
    h2 {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .app-button {
    height: 54px;
  }

  .checkmark {
    background: white;
  }

  #primary-call-to-action {
    background: rgb(3, 3, 46);
    padding: 1em 0;
  }

  #benefits {
    .img-fluid {
      width: 100%;
      max-width: 400px;
    }
  }

  #earning-trust {
    blockquote,
    cite {
      font-family: 'Merriweather', 'Times New Roman', Times, serif;
      font-style: italic;
      font-weight: 300;
    }

    blockquote {
      font-size: 1.1em;
    }

    cite {
      font-size: 0.65em;
    }

    .icon {
      filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));
    }
  }
}

@media (max-width: breakpoints.$breakpoint-bs-xs) {
  html[data-style-scope='marcom'] .app-button {
    height: 40px;
  }
}
