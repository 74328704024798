/* MarCom-scoped styles (public pages) */

@use 'breakpoints';

html[data-style-scope='marcom'] {
  body {
    font-size: 20px;
    background-color: var(--bs-black);
  }

  header,
  footer {
    background-color: var(--bs-black);
  }

  .wrapper,
  footer .container-fluid {
    max-width: 1000px;
    margin: 0 auto;
    padding: 1em 2em;
  }

  main .wrapper {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  h1,
  h2 {
    margin-bottom: 0;
  }

  main {
    overflow: hidden;
    background-color: #f5f5f5;
  }

  footer {
    padding-top: 2em;
    padding-bottom: 2em;
    color: #808080;
    a {
      display: inline-block;
      color: white;
      padding: 2px 0;
    }
  }

  .floating-card {
    box-shadow: 0px 0px 8px rgba(52, 72, 84, 0.05);
    background: white;
    padding: 1em;
    border-radius: 12px;
    overflow: hidden;
  }
}

@media (max-width: breakpoints.$breakpoint-1) {
  html[data-style-scope='marcom'] body {
    font-size: 18px;
  }
}

@media (max-width: breakpoints.$breakpoint-2) {
  html[data-style-scope='marcom'] body {
    font-size: 16px;
  }
}

@media (max-width: breakpoints.$breakpoint-3) {
  html[data-style-scope='marcom'] .wrapper {
    padding: 0.7em 1.4em;
  }
}

@media (max-width: breakpoints.$breakpoint-bs-xs) {
  html[data-style-scope='marcom'] footer {
    text-align: center;
  }
}
