/* Project-wide styles; not scoped */
html {
  height: 100%;
}

body {
  padding: 0;
  height: 100%;
}

.modal-content{
  margin-top: env(safe-area-inset-top);
}

/*
 * General overrides for app-wide elements
 */
button {
  cursor: pointer;
}

/*
 * Bootstrap overrides
 */
a.text-muted:hover {
  color: var(--bs-secondary-color);
}

.alert {
  overflow-wrap: anywhere;
}

.placeholder {
  border-radius: 8px;
  opacity: 0.1;
}
.placeholder-wave {
  display: inherit;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.2) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.2) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

/*
 * Utilities
 */
.small-font {
  font-size: 0.8em;
  line-height: 1.4;
}

.cap-first-letter::first-letter {
  text-transform: capitalize;
}

.big-spinner {
  display: flex;
  flex-direction: column;
  place-items: center;
  height: 100%;
  justify-content: center;
  opacity: 0;
  animation: fade-in 3s 300ms forwards;
}

html[data-style-scope='init'] {
  #root {
    height: 100%;
  }
}

/*
   * Dark mode
   */
@media (prefers-color-scheme: dark) {
  .logo {
    filter: invert(1);
  }
}
